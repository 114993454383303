import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Icon } from '@wam/icons';

@Component({
  selector: 'cl-functional-button',
  templateUrl: './functional-button.component.html',
  styleUrls: ['./functional-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FunctionalButtonComponent {
  @Input() icon: Icon;
  @Input() size = '32px';
  @Input() @HostBinding('class.disabled') disabled = false;
}
